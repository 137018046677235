import request from '../utils/request';

export const GetDingHistorical = (query) => {
    return request({
        url: '/sys/dingHistoricalApproval/GetDingHistorical',
        method: 'POST',
        data: query
    });
};

export const GetDingHistoricalDetails = (param) => {
    return request({
        url: '/sys/dingHistoricalApproval/GetDingHistoricalDetails',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};



export const GetDingHistoricalType = (query) => {
    return request({
        url: '/sys/dingHistoricalApproval/GetDingHistoricalType',
        method: 'GET',
        data: query
    });
};
