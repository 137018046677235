<template>
  <div style="padding: 20px">
    <el-input
        v-model="query.promoterName"
        placeholder="发起人姓名"
        style="width: 160px;margin-right: 10px"
    ></el-input>
    <el-select
        clearable
        v-model="query.type"
        placeholder="表单类型"
    >
      <el-option
          v-for="(item, index) in query.typeList"
          :key="index"
          :label="item.dictLabel"
          :value="item.dictType"
      ></el-option>
    </el-select>
    <el-date-picker
        style="width: 260px;margin: 10px"
        v-model="query.startAndEndTime"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
    >
    </el-date-picker>
    <el-select clearable
        v-model="query.state"
        placeholder="状态">
      <el-option label="完成" value="完成"></el-option>
      <el-option label="已撤销" value="已撤销"></el-option>
      <el-option label="审批中" value="审批中"></el-option>
      <el-option label="已修改" value="已修改"></el-option>
    </el-select>
    <el-button style="margin:0 10px" type="primary" @click="select()">查 询</el-button>

    <el-table :data="tableList" border style="width: 100%;margin-top: 20px">
      <el-table-column prop="approvalCode" label="审批编号" />
      <el-table-column prop="title" label="表单名称" width="180" />
      <el-table-column prop="promoterName" label="发起人" />
      <el-table-column prop="promoterDept" show-overflow-tooltip label="发起人部门" />
      <el-table-column prop="state" label="状态" />
      <el-table-column prop="result" label="结果" />
      <el-table-column prop="startTime" label="审批开始时间" width="180" />
      <el-table-column prop="endTime" label="审批结束时间" />
      <el-table-column
          fixed="right"
          label="详情"
          width="100">
        <template #default="scope">
          <el-button @click="GetDingHistoricalDetails(scope.row)" type="text" size="small">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="query.pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>

    <el-dialog
        v-model="detailsDialog"
        width="1000px"
        draggable
        destroy-on-close
    >
      <div style="height: 500px;overflow: auto">
        <div v-for="(item,index) in detailsList" :key="index" style="background-color: #F5F7FA;padding: 10px;border-radius: 14px;margin-bottom: 20px">
          <h3>表单详细信息{{index+1}}</h3>
          <div v-for="(detail,dndex) in item.details" :key="dndex" style="background-color: white;padding: 10px;margin: 10px;border-radius: 10px">
            {{detail.attachKey}}：{{detail.attachValue}}
          </div>
        </div>
      </div>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="detailsDialog= false">取 消</el-button>
            <el-button type="primary" @click="detailsDialog=false">确 定</el-button>
          </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {GetDingHistorical,GetDingHistoricalType,GetDingHistoricalDetails} from  "@/api/dingding.js"
export default {
  name: "DingHistorical",
  data() {
    return {
      query:{
        type:null,
        typeList:[],
        promoterName:"",
        pageIndex:1,
        pageSize:10,
        pageTotal:0,
        startAndEndTime:null,
        startTime:null,
        endTime:null,
        state:null
      },
      tableList:[],
      detailsList:[],
      detailsDialog:false
    }
  },
  methods:{
    dateFormat(val) {
      var date = new Date(val)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      return time
    },
    GetDingHistoricalDetails(item){
      console.log(item)
      GetDingHistoricalDetails(item.id).then(val=>{
        if (val.code == 200){
          this.detailsList = val.data;
          this.detailsDialog = true;
        }
      })
    },
    handlePageChange(index){
      this.query.pageIndex = index;
      this.select();
    },
    select(){
      if (this.query.startAndEndTime){
        this.query.startTime = this.dateFormat(this.query.startAndEndTime[0]);
        this.query.endTime = this.dateFormat(this.query.startAndEndTime[1]);
      }else {
        this.query.startTime = null;
        this.query.endTime = null;
      }
      GetDingHistorical(this.query).then(val=>{
        if (val.code == 200){
          this.query.pageTotal = val.data.total;
          this.tableList = val.data.records;
        }
      })
    }
  },
  created() {
    GetDingHistoricalType().then(val=>{
      if (val.code == 200){
        this.query.typeList = val.data;
        console.log(this.query.typeList)
      }

    })
    this.select();
  }
}
</script>

<style scoped>
.pagination{
  padding: 20px;
}
</style>